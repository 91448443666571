











































import type { Picture } from '@/inc/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'

interface PodcastHeroSticky {
  displayTitle: string
  picture?: Picture
  video?: string
  platforms: {
    title: string
    items: {
      type: string
      url: string
    }[]
  }
}

export default defineComponent({
  name: 'PodcastHeroSticky',
  components: {},
  props: {
    content: {
      type: Object as PropType<PodcastHeroSticky>,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const isVisibleRef = ref(false)
    const progressRef = ref(0)

    // Update progress ref based on video progress
    const onProgress = (percentage: number) => {
      progressRef.value = percentage
    }

    return {
      isVisibleRef,
      progressRef,
      onProgress,
    }
  },
})
