


























































































































import type { Picture } from '@/inc/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'

import GVideo from '@/components/g/Video.vue'
import UiPicture from '@/components/ui/Picture.vue'
import PodcastHeroSticky from '@/components/podcast/HeroSticky.vue'

interface PodcastHero {
  displayTitle: string
  picture?: Picture
  video?: string
  playLabel: string
  attendees: {
    title: string
    items: {
      picture: Picture
      headline: string
      name: string
      jobTitle: string
    }[]
  }
  platforms: {
    title: string
    items: {
      type: string
      url: string
    }[]
  }
  youtubeId?: string
}

export default defineComponent({
  name: 'PodcastHero',
  props: {
    content: {
      type: Object as PropType<PodcastHero>,
      required: true,
    },
  },

  components: {
    PodcastHeroSticky,
    UiPicture,
  },

  setup() {
    const rootElRef = ref<HTMLElement | null>(null)
    const infoElRef = ref<HTMLElement | null>(null)
    const videoRef = ref<InstanceType<typeof GVideo> | null>(null)
    const showStickyRef = ref(false)
    const progressRef = ref(0)

    // Update sticky progress based on video progress
    const onProgress = (percentage: number) => {
      progressRef.value = percentage
    }

    // Toggle sticky appearance + move video
    // Show sticky if the user has scrolled past the hero
    const onScroll = (x: number, y: number) => {
      if (!infoElRef.value || !videoRef.value) {
        return
      }

      // If video is paused, don't show sticky
      if (
        !showStickyRef.value &&
        (!videoRef.value.isPlayingRef || videoRef.value.playerRef.isPaused)
      ) {
        showStickyRef.value = false

        return
      }

      const infoTop = y + infoElRef.value.getBoundingClientRect().top
      showStickyRef.value = y > infoTop
    }

    return {
      rootElRef,
      infoElRef,
      videoRef,
      showStickyRef,
      progressRef,
      onProgress,
      onScroll,
    }
  },
})
